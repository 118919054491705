<template>
    <div class="row">
        <div class="col-12">
            <div class="card pt-4 mb-xl-9">
                <div class="card-header border-0">
                    <div class="card-title">
                        <h2 class="fw-bolder">{{ $t("pages.ideas.title") }}</h2>
                    </div>
                </div>
                <div class="card-body pt-0">
                    <div class="fw-bolder fs-2">
                        <div class="fs-7 fw-normal text-muted">
                            {{ $t("pages.ideas.desc") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 mb-3">
            <div class="alert alert-primary" role="alert">
                <div class="d-flex justify-content-between">
                    <p class="pt-4">{{$t("pages.ideas.ideaToActionPlan")}} </p>
                    <button class="btn btn-primary btn-sm" @click="$router.push({ name: 'roadmap' })">{{$t("pages.ideas.toRoadmap")}}</button>
                </div>

            </div>
        </div>
        <div class="col-4 mb-4" v-for="category in this.categories" v-bind:key="category.pk">
            <div class="card bg-success bg-opacity-25 h-100">
                <div class="card-header flex-nowrap border-0 pt-9">
                    <div class="card-title m-0">
                        <i class="fas fa-lightbulb fs-3 me-3 text-gray-900"></i>
                        <a href="#" class="fs-4 fw-bold text-hover-primary text-gray-900 m-0">{{ category.label }}</a>
                    </div>
                </div>
                <div class="card-body d-flex flex-column pb-8">
                    <div class="d-flex align-items-center flex-wrap mb-5 fs-6">
                        <div class="text-success fw-bolder me-2">
                            <span class="badge badge-light"><strong>{{ category.ideas.length }} idée(s)</strong></span>
                        </div>
                    </div>
                    <div class="d-flex align-items-center fw-bold">
                        <router-link :to="'/ideas-detail/' + category.pk + '/'">
                            <a class="btn btn-success btn-sm btn-light btn-active-light-success">{{ $t("pages.ideas.accessIdeas") }}</a>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ideaBoxApi from "@/core/services/IdeaBox";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "ideas",
    components: {},
    data: function () {
        return {
            categories: [],
        };
    },
    methods: {
        getCategories() {
            ideaBoxApi.getCategories().then((response) => {
                this.categories = response.data["categories"];
            });
        },
    },
    setup() {
        const { t } = useI18n();
        setCurrentPageBreadcrumbs(t("pages.ideas.title"), []);
        return { t };
    },
    watch: {
        "$i18n.locale": function() {
            setCurrentPageBreadcrumbs(this.t("pages.ideas.title"), []);
        },
    },
    created() {
        this.getCategories();
    },
});
</script>
